let BACKEND_SERVER = null;
let DATAPI_BACKEND_SERVER = null;

if (process.env.REACT_APP_BACKEND_SERVER) {
  BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
} else {
  BACKEND_SERVER = 'http://127.0.0.1:5000/api/';
}

if (process.env.REACT_APP_DATAPI_SERVER) {
  DATAPI_BACKEND_SERVER = process.env.REACT_APP_DATAPI_SERVER;
} else {
  DATAPI_BACKEND_SERVER = 'http://localhost:26265/news/';
}

export const BASENAME = '';
export const BASE_URL = '/app/home';
export const BASE_TITLE = ' | Alejandría ';
export const API_SERVER = BACKEND_SERVER;
export const DATAPI_SERVER = DATAPI_BACKEND_SERVER;

export const CONFIG = {
    layout: 'vertical',
    subLayout: '',
    collapseMenu: true,
    layoutType: 'menu-dark',
    navIconColor: false,
    headerBackColor: 'header-default',
    navBackColor: 'navbar-default',
    navBrandColor: 'brand-default',
    navBackImage: false,
    rtlLayout: false,
    navFixedLayout: true,
    headerFixedLayout: false,
    boxLayout: false,
    navDropdownIcon: 'style1',
    navListIcon: 'style1',
    navActiveListColor: 'active-default',
    navListTitleColor: 'title-default',
    navListTitleHide: false,
    configBlock: true,
    layout6Background: 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)',
    layout6BackSize: ''
};
