import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigContext } from '../../../contexts/ConfigContext';
import useWindowSize from '../../../hooks/useWindowSize';

import { API_SERVER } from '../../../config/constant';
import { LOGOUT } from '../../../store/actions';
import axios from 'axios';

import NavLogo from './NavLogo';
import NavContent from './NavContent';
import navigation from '../../../menu-items';

const Navigation = () => {
    const configContext = useContext(ConfigContext);
    const { collapseMenu } = configContext.state;
    const windowSize = useWindowSize();

    const account = useSelector((state) => state.account);
    const dispatcher = useDispatch();

    const handleLogout = () => {
        axios
            .post(API_SERVER + 'users/logout', {}, { headers: { Authorization: `${account.token}` } })
            .then(function (response) {
                dispatcher({ type: LOGOUT });
            })
            .catch(function (error) {
                console.log('error - ', error);
            });
    };

    let navClass = [
        'pcoded-navbar',
        'menupos-static',
        'menu-dark',
        'navbar-default',
        'brand-default',
        'drp-icon-style1',
        'menu-item-icon-style1',
        'active-default',
        'title-default'
    ];

    if (windowSize.width < 992 && collapseMenu) {
        navClass = [...navClass, 'mob-open'];
    } else if (collapseMenu) {
        navClass = [...navClass, 'navbar-collapsed'];
    }

    let navStyle;

    let navBarClass = ['navbar-wrapper'];

    let navContent = (
        <div className={navBarClass.join(' ')}>
            <NavLogo />
            <NavContent navigation={navigation.items} handleLogout={handleLogout}/>
        </div>
    );
    if (windowSize.width < 992) {
        navContent = (
            <div className="navbar-wrapper">
                <NavLogo />
                <NavContent navigation={navigation.items}  handleLogout={handleLogout}/>
            </div>
        );
    }
    return (
        <React.Fragment>
            <nav className={navClass.join(' ')} style={navStyle}>
                {navContent}

            </nav>
        </React.Fragment>
    );
};

export default Navigation;
