const menuItems = {
    items: [
        {
            id: 'navigation',
            title: 'Componentes',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'dashboard',
                    title: 'Buscador',
                    type: 'item',
                    url: '/app/home',
                    icon: 'feather icon-search'
                }
            ]
        },
        {
            id: 'user',
            title: 'Usuario',
            type: 'group',
            icon: 'icon-pages'
        },
        {
            id: 'logout',
            title: 'Cerrar Sesión',
            type: 'item',
            icon: 'feather icon-log-out',
            classes: 'nav-item',
            url: '',
            target: false,
            external: false
        },
        {
            id: 'resources',
            title: 'Nosotros',
            type: 'group',
            icon: 'icon-tool',
            children: [
                {
                    id: 'documentation',
                    title: 'Documentación',
                    type: 'item',
                    url: 'https://gitlab.com/ec_data/alejandria-ui',
                    icon: 'feather icon-server',
                    external: true
                },
                {
                    id: 'license',
                    title: 'Licencia',
                    type: 'item',
                    url: 'https://gitlab.com/ec_data/alejandria-ui',
                    icon: 'feather icon-help-circle',
                    external: true
                }
            ]
        }
    ]
};

export default menuItems;
